@import '../../../../App.scss';
#index {
	margin: 0;
	width: 210mm;
	height: 297mm;
	box-sizing: border-box;
	overflow: hidden;
	p {
		margin: 0;
	}
	.index {
		width: 210mm;
		font-family: $qualitas-font-title;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		height: 260mm;	
	}
	.index-content {
		width: 210mm;
		padding: 30px;
	}
	.index-title {
		font-family: $qualitas-font-title;
		font-weight: 700;
		font-size: 26px;
		margin-bottom: 20px;
	}
	.index-section{
		:first-child{
			font-weight: bold;
		}
		border-bottom: 1px solid $qualitas-grey;
		padding: 15px 0px;
	}


}