@import '../../../../App.scss';
p {
    margin: 0;
}
.report-header {
    font-family: $qualitas-font-text;
    background-color: #000000;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 210mm;
    height: 55px;
    z-index: 10;
    position: relative;
}
.confidential-text{
    position: absolute;
    top: 292mm;
    width: 100%;
    text-align: center;
    font-family: $qualitas-font-text;
    z-index: 1000;
    font-size: 9px;
    letter-spacing: 5px;
    color: $qualitas-dark-grey;
}
.page{
    position: absolute;
    right: 7mm;
    color:$qualitas-black;
    bottom: -280mm;
    font-family: $qualitas-font-text;
    z-index: 1000;
    font-size: 10px;
}

.report-logoQualitas {
    width: 78px;
    margin: 13px 0 11px 28px;
}

.report-header-text {
    font-weight: 300;
    margin: 11px 25px 11px 0;
    letter-spacing: 0.4px;
    
    p {
        margin: 0;
        font-size: 13px;
    }
    span{
        color: $qualitas-green;
        font-weight: 400;
    }
}
