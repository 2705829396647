@import "../../../../App.scss";

#portfolioPerformance {
	margin: 0;
	height: 297mm;
	overflow: hidden;

	p {
		margin: 0;
	}

	.content {
		width: 210mm;
		height: 297mm;
		padding: 0;
		margin: 0;
		overflow: hidden;
		box-sizing: border-box;
	}

	.portfolioPerformance {
		font-family: $qualitas-font-text;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
	.portfolioPerformance tr,
	.portfolioPerformance td {
		border-bottom: 1px solid $qualitas-light-grey
	}
	.portfolioPerformance tr:first-of-type{
		border-bottom: none;
	}
	.portfolioPerformance-table-title-line{
		border-bottom: 1px solid $qualitas-light-grey;
		color: $qualitas-dark-grey;
		font-size: 8px;
	}


	.portfolioPerformance td:first-of-type {
		font-weight: bold;
	}
	.portfolioPerformance-table-rows td:not(:first-child) {
		border-left: 11px solid white;
		text-align: center;
	}
	.portfolioPerformance-table-subtitle {
		border-top: 1px solid rgb(211, 211, 211);
		font-weight: bold;
	}
	.portfolioPerformance-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 210mm;
		height: 55px;
	}

	.portfolioPerformance-logoQualitas {
		width: 78px;		
		margin: 13px 0 11px 28px;
	}

	.portfolioPerformance-header-text {
		margin: 11px 36px 11px 0;
		
		p {
			margin: 0;
			font-size: 7px;
		}
	}

	.portfolioPerformance-content {
		box-sizing: border-box;
		width: 100%;
		padding: 25px;
	}
	.portfolioPerformance-subtitle {
		font-size: 20px;
		font-family: $qualitas-font-title;
		font-weight: 700;
	}

	.portfolioPerformance-table {
		border-collapse: collapse;
		border-spacing: 0;
		margin-top: 14px;
		font-family: $qualitas-font-text;
		font-size: 11px;
	}
	.portfolioPerformance-table td,
	.portfolioPerformance-table th,
	.portfolioPerformance-table-totals td {
		padding: 8px 5px;
		text-align: center;
		text-overflow: ellipsis;

	}
	.portfolioPerformance-table-title {
		letter-spacing: 1px;
		color: $qualitas-dark-grey;
		font-size: 7px;
	}
	.portfolioPerformance-table-title:first-of-type{
		text-align: start;
	}
	.portfolioPerformance-table-boldLine {
		border-top: 1px solid rgb(201, 201, 201);
	}
	.portfolioPerformance-table-boldLine td,
	.portfolioPerformance-table-totals td {
		font-size: 9px;
	}
	.portfolioPerformance-table-boldLine td:first-child{
		text-align: left;
		padding: 8px 0px 8px 12px;
	}
	.portfolioPerformance-table-img-container {
		width: 100%;
		height: 100%;
	}
	  
	.portfolioPerformance-table-img {
		max-width: 100%;
		max-height: 100%;
	}
	.portfolioPerformance-references {
		display: flex;
		font-size: 9px;
		justify-content: space-between;
	}

	.portfolioPerformance-table-totals {
		border-top: 1px solid $qualitas-grey;
		background-color: $qualitas-light-grey
	}
	.portfolioPerformance-table-totals td:first-of-type {
		text-align: left;
		padding-left: 12px;
	}
	.portfolioPerformance-table-totals:last-of-type td {
		font-weight: bold !important;
		background-color: $qualitas-green;
		font-size: 11px;
	}

}