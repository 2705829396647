@import "../../Variables.scss";

.net-cash {
	.dx-datagrid {
		background: none;

		.dx-toolbar {
			background: none;
		}

		.dx-datagrid-content {
			background: #fff;
		}

		.dx-icon-trash {
			color: $qualitas-dark-grey;
		}

		.dx-header-row {
			background-color: $qualitas-dark-grey;
			color: $qualitas-white
		}
	}
}