@import "../../../../App.scss";

#newFunds {
  margin: 0;
  position: relative;
  height: 297mm;

  p {
    margin: 0;
  }

  .newFunds-content {
    box-sizing: border-box;
    width: 100%;
    height: 250mm;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .content {
    width: 210mm;
    margin: 0;
    box-sizing: border-box;
    padding: 0;
  }

  .newFunds {
    font-family: $qualitas-font-text;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  .newFunds-subtitle {
    margin: 25px 0 0 25px;
    font-size: 20px;
    font-family: $qualitas-font-title;
    font-weight: 700;
  }

  .newFunds-item {
    padding: 25px 0px;
    box-sizing: border-box;
    height: 240px;
    .newFunds-title {
      display: flex;
      justify-content: space-between;
      padding: 0 25px;
      height: 12mm;
      background-color: $qualitas-light-grey;
      align-items: center;
      font-family: $qualitas-font-title;
      font-size: 20px;
      font-weight: 600;
      box-sizing: border-box;
      span {
        font-size: 14px;
        font-weight: normal;
        font-family: $qualitas-font-text;
        margin-left: 5px;
      }
    }
    .newFunds-headerInfo {
      height: 50px;
      margin-top: 12px;
      margin: 0 25px;
      border-bottom: 1px solid $qualitas-grey;
      display: grid;
      grid-template-columns: 135px 105px 80px 90px 100px 85px 150px;
      grid-template-rows: 1fr;
      box-sizing: border-box;
    }
    .newFunds-headerInfo-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      font-size: 9.5px;
      padding: 0 9px;
      border-right: 1px solid $qualitas-grey;
      img {
        max-height: 30px !important;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        max-width: 130px !important ;
      }
      p:last-of-type {
        font-weight: 700;
      }
    }
    .newFunds-headerInfo-item:last-of-type {
      border: none;
    }
    .newFunds-info {
      margin: 0 25px;
      a{
        color: $qualitas-black;
      }
     }
    .newFunds-info-text {
      font-size: 11px;
      text-align: justify;
      padding-top: 10px;
      p {
        padding-top: 10px;
      }
      .newFunds-info-subtitle {
        font-weight: 700;
      }
    }

    .newFunds-chart {
      padding: 0 0 0 40px;
      p {
        font-family: $qualitas-font-text;
        font-size: 12px;
        font-weight: normal;
      }
      .newFunds-chart-title {
        text-align: center;
      }
      .newFunds-chart {
        width: 160px;
        margin-left: 30px;
      }
    }
    .newFunds-chart-legend {
      display: grid;
      margin-top: 10px;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 0px;
      text-align: center;
      p,
      span {
        font-size: 10px;
      }
      span {
        font-weight: 700;
        color: $qualitas-black;
      }
      .newFunds-chart-legend-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .newFunds-chart-legend-item > div {
        height: 8px;
        width: 8px;
        border-radius: 10px;
      }
      .newFunds-chart-legend-item:nth-child(1) div {
        background-color: $qualitas-green;
      }
      .newFunds-chart-legend-item:nth-child(2) div {
        background-color: #8f8f8f;
      }
      .newFunds-chart-legend-item:nth-child(3) div {
        background-color: $qualitas-green;
      }
      .newFunds-chart-legend-item:nth-child(4) div {
        background-color: #8f8f8f;
      }
    }
    .newFunds-chart-labels {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
    }
  }
}
