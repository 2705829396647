@import "../../../../App.scss";
#portfolioBreakdown {
  margin: 0;
  width: 210mm;
  height: 297mm;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  p {
    margin: 0;
  }

  .content {
    width: 210mm;
    margin: 0;
    padding: 0;
    font-family: $qualitas-font-text;
  }

  .portfolioBreakdown {
    padding: 25px 25px;
    font-family: $qualitas-font-text;
    box-sizing: border-box;
    position: relative;
  }
  .portfolioBreakdown-content {
    box-sizing: border-box;
    width: 100%;
  }

  .portfolioBreakdown-subtitle {
    font-size: 20px;
    font-family: $qualitas-font-title;
    font-weight: 700;
    span {
      font-family: $qualitas-font-title !important;
      color: $qualitas-dark-grey;
    }
  }

  .portfolioBreakdown-header {
    display: flex;
	width: 100%;
	gap: 20px;
	align-items: center;
}

  .portfolioBreakdown-headerInfo {
    width: 170px;
    padding: 10px 0px;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 60px 60px;
  }
  .portfolioBreakdown-headerInfo-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    font-size: 11px;
    padding: 5px 9px;
    border-top: 1px solid $qualitas-grey;
    img {
      align-self: center;
      max-height: 35px !important;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
    p:last-of-type {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .portfolioBreakdown-headerInfo-item:last-of-type {
    border-bottom: 1px solid $qualitas-grey;
    border-top: 1px solid $qualitas-grey;
  }

  .portfolioBreakdown-chart {
	width: 320px;
    p {
      font-family: $qualitas-font-text;
      font-size: 14px;
      font-weight: normal;
	  text-align: center;
    }
    .portfolioBreakdown-chart-title {
      text-align: center;
    }
  }
  .portfolioBreakdown-chart-section{
	display: flex;
	width: 350px;
	gap: 25px;
	align-items: center;
  }
  .portfolioBreakdown-chart-legend {
    display: grid;
    margin-top: 10px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	height: 120px;
    text-align: center;
    p,
    span {
      font-size: 10px;
    }
    span {
      font-weight: 700;
      color: $qualitas-black;
    }
    .portfolioBreakdown-chart-legend-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .portfolioBreakdown-chart-legend-item > div {
      height: 8px;
      width: 8px;
      border-radius: 10px;
    }
    .portfolioBreakdown-chart-legend-item:nth-child(odd) div {
      background-color: $qualitas-green;
    }
    .portfolioBreakdown-chart-legend-item:nth-child(even) div {
      background-color: #8f8f8f;
    }
  }
  .portfolioBreakdown-chart-labels {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
	p{
		font-size: 12px;
	}
  }

  .portfolioBreakdown-analysis-table{
		width: 100%;
		font-family: $qualitas-font-text;
		font-size: 8px;
		border-collapse: collapse;
    margin-top: 10px;
	}
	tr > th{
		color: $qualitas-dark-grey;
		border-bottom: 1px solid $qualitas-black; 
		height: 35px;
	}
	
	.manager-opinion {
		width: 8px;
		height: 8px;
		margin: auto;
		border-radius: 8px;
	}
  .portfolioFunds-analysis-references-moc{
		display: flex;
    align-items: center;
		gap: 8px;
	}
	.moc-reference {
		width: 8px;
		height: 8px;
		border-radius: 8px;
	}
	.ref-1{
		background-color: $qualitas-grey;
	}
	.ref-2{
		border: 1px solid $qualitas-dark-grey
	}
	td{
		text-align: center;
		font-size: 10px;
		height: 42px;
		border-bottom: 1px solid $qualitas-grey;
    box-sizing: border-box;
	}
	.portfolioBreakdown-analysis-small{
		font-size: 9px;
		text-align: start;
	}

	.portfolioBreakdown-analysis-grey{
		background-color: $qualitas-light-grey;
	}
	.opt-1{
		background-color: #15D63F;
	}
	.opt-2{
		background-color: #B3D615;
	}
	.opt-3{
		background-color: #FFE500;
	}
	.opt-4{
		background-color: #F03107;;
	}

	.portfolioBreakdown-analysis-references{
		font-size: 9px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-right: 22px;
		margin-top: 10px;
	
	}
	.portfolioBreakdown-analysis-ref-opinions{
		display: flex;
		gap: 15px;
	}
	.portfolioBreakdown-analysis-ref-opinion-item{
		display: flex;
		gap: 7px;
		align-items: center;
	}
	.progress-bar{
		width: 120px;
		height: 40%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 10px;
	}
  .portfolioBreakdown-analysis{
    margin-top: 20px;
    p{
      font-size: 14px;
      margin-top: 25px;
      font-weight: 600;
      font-family: $qualitas-font-title;
    }
  }
  .portfolioBreakdown-subtitle{
    font-family: $qualitas-font-title;
		font-weight: 700;
		font-size: 20px;
  }
}
