@import '../../../../App.scss';
#fundFigures {
	margin: 0;
	width: 210mm;
	height: 297mm;
	overflow: hidden;

	p {
		margin: 0;
	}

	.content {
		width: 210mm;
		margin: 0;
		padding: 23px 0 0 0;
	}

	.fundFigures {
		box-sizing: border-box;
		position: relative;
	}

	.fundFigures tr{
		border-bottom: 1px solid rgb(211, 211, 211);
	}

	.fundFigures tr>td:first-child {
		font-size: 12px;
	}

	.fundFigures td:not(:first-child) {
		text-align: right;
	}
	
	.fundFigures td:last-of-type {
		font-weight: bold;
	}

	.fundFigures-table-rows td:not(:first-child) {
		text-align: center;
	}

 	.fundFigures-table-subtitle {
/* 		border-top: 1px solid $qualitas-grey;
 */		font-weight: bold;
	} 


	.fundFigures-header {
		background-color: #000000;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 210mm;
		height: 55px;
	}

	.fundFigures-logoQualitas {
		width: 78px;		
		margin: 13px 0 11px 28px;
	}

	.fundFigures-header-text {
		margin: 11px 36px 11px 0;
		
		p {
			margin: 0;
			font-size: 11px;
		}
	}

	.fundFigures-content {
		box-sizing: border-box;
		width: 100%;
		padding: 20px;
	}

	.fundFigures-subtitle {
		margin-bottom: 20px;
		font-size: 20px;
		font-family: $qualitas-font-title;
		font-weight: 700;
		span{
			font-family: $qualitas-font-title !important;
			font-weight: 500;
		}
	}

	.fundFigures-table {
		margin-top: 25px;
		font-family: $qualitas-font-text;
		font-size: 11px;
	}

	.fundFigures-table {
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;
	}

	.fundFigures-table td,
	.fundFigures-table th {
		padding: 5px 5px;
		height: 14px;
	}

	.fundFigures-table tr:last-of-type {
		border-bottom: 1px solid $qualitas-black;
	}

	.fundFigures-table-title {
		letter-spacing: 1px;
		font-size: 9px;
	}
	.fundFigures-table-title:nth-child(2){
		background-color: $qualitas-light-grey;
	}

	.fundFigures-table-coloredCells {
		background-color: $qualitas-light-grey;
		border-bottom: 1px solid $qualitas-grey;
	}

	.fundFigures-table-green {
		background-color: $qualitas-green;
		font-weight: 600;
	}

	.fundFigures-table-lightGrey {
		color: rgb(94, 94, 94);
		text-align: center !important;
		font-weight: 300;
	}

	.fundFigures-table-img-container {
		position: relative;
		
		width: 100%;
		align-self: end;
	}
	  
	.fundFigures-table-img {
		bottom: 0px;
		max-width: 100%;
		max-height: 100%;
	}
	.fundFigures-references {
		font-size: 9px !important;
		font-weight: normal !important;
		text-align: center;
		border-bottom: none !important;
	}
	.fundFigures-table-beforeBold {
		border-bottom: 1px solid $qualitas-dark-grey !important;
	}



}