@import "../../../../App.scss";

#coinvestSummary {
  margin: 0;
  position: relative;
  height: 297mm;

  p {
    margin: 0;
  }

  .coinvestSummary-content {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
	margin-top: 35px;
  }

  .content {
    width: 210mm;
    margin: 0;
    box-sizing: border-box;
	padding: 0;
  }

  .coinvestSummary {
    font-family: $qualitas-font-text;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  .coinvestSummary-subtitle {
    margin: 25px 0 0 30px;
    font-size: 20px;
    font-family: $qualitas-font-title;
    font-weight: 700;
  }

  .coinvestSummary-item {
    box-sizing: border-box;

    .coinvestSummary-title {
      font-family: $qualitas-font-title;
      padding-left: 25px;
      font-size: 20px;
      font-weight: 600;
      height: 12mm;
      display: flex;
      align-items: center;
      background-color: $qualitas-light-grey;
      span {
        font-size: 14px;
        font-weight: normal;
        font-family: $qualitas-font-text;
        margin-left: 5px;
      }
    }
    .coinvestSummary-headerInfo {
      height: 50px;
      margin: 0px 30px;
      border-bottom: 1px solid $qualitas-grey;
      display: grid;
      grid-template-columns: 100px 120px 60px 100px 100px 60px 180px;
      grid-template-rows: 1fr;
    }
    .coinvestSummary-headerInfo-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      font-size: 9.5px;
      padding: 0 9px;
      border-right: 1px solid $qualitas-grey;
      img {
        max-height: 35px !important;
        filter: grayscale(100%);
        max-width: 120px !important;
		    -webkit-filter: grayscale(100%); 
      }
      p:last-of-type {
        font-weight: 700;
      }
    }
    .coinvestSummary-headerInfo-item:last-of-type {
      border: none;
    }
    .coinvestSummary-info {
      display: grid;
      margin: 30px 20px;
      grid-template-columns: 1fr 280px;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      a{
        color: $qualitas-black;
      }
    }
    .coinvestSummary-info-text {
      min-height: 280px;
      text-align: justify;
      font-size: 11px;
      p {
        margin-top: 12px;
      }
      .coinvestSummary-info-subtitle {
        font-weight: 700;
      }
    }

    .coinvestSummary-chart {
      padding: 0 20px 0 20px;
	  P:first-of-type{
		text-align: center;
	  }
      p {
        font-family: $qualitas-font-text;
        font-size: 12px;
        font-weight: normal;
      }
      .coinvestSummary-chart-title{
        text-align: center;
      }
      .coinvestSummary-chart {
        width: 120px;
        margin-left: 30px;
      }
    }
    .coinvestSummary-chart-legend {
      display: flex;
	  justify-content: center;
	  gap: 50px;
      margin-top: 10px;
      text-align: center;
      p,
      span {
        font-size: 10px;
      }
      span {
        font-weight: 700;
        color:$qualitas-black;
      }
      .coinvestSummary-chart-legend-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .coinvestSummary-chart-legend-item > div {
        height: 8px;
        width: 8px;
        border-radius: 10px;
      }
      .coinvestSummary-chart-legend-item:nth-child(1) div {
        background-color: $qualitas-green;
      }
      .coinvestSummary-chart-legend-item:nth-child(2) div {
        background-color: #8f8f8f;
      }
      .coinvestSummary-chart-legend-item:nth-child(3) div {
        background-color: $qualitas-green;
      }
      .coinvestSummary-chart-legend-item:nth-child(4) div {
        background-color: #8f8f8f;
      }
    }
    .coinvestSummary-chart-labels {
      p{
        font-size: 11px;
      }
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
    }
  }
}
