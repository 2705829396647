@import '../../../../App.scss';
#fundCharts {
	margin: 0;
	width: 210mm;
	height: 297mm;

	p {
		margin: 0;
	}
	
	.fundCharts {
		width: 210mm;
		box-sizing: border-box;
		position: relative;
	}

	.fundCharts-table-subtitle {
		border-top: 1px solid rgb(211, 211, 211);
		font-weight: bold;
	}

	.fundCharts-header {
		background-color: #000000;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 210mm;
		height: 55px;
	}

	.fundCharts-logoQualitas {
		width: 78px;		
		margin: 13px 0 11px 28px;
	}

	.fundCharts-header-text {
		margin: 11px 36px 11px 0;
		
		p {
			margin: 0;
			font-size: 11px;
		}
	}

	.fundCharts-content {
		box-sizing: border-box;
		width: 100%;
	}

	.fundCharts-subtitle {
		font-size: 26px;
		font-family: $qualitas-font-title;
		font-weight: 700;
		text-align: center;
		padding: 20px 0 0 20px;
		margin: 26px 0px 10px 0px;
	}

	.fundsCharts-container {
		margin-top: 24px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		background-color: $qualitas-green;
		box-sizing: border-box;
	}

	.fundsCharts-infoBox {
		font-family: $qualitas-font-text;
		font-weight: 600;
		width: 90px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		line-height: 20px;
		font-size: 12px;
		border-right: 1px solid $qualitas-dark-grey
	}
	.fundsCharts-infoBox:last-of-type{
		border: none;
	}

	.fundsCharts-infoBox p {
		font-family: $qualitas-font-text;
		margin: 0px;
	}

	.fundsCharts-infoBox p:last-of-type {
		font-size: 16px;
		font-weight: 700;
	}

	.fundsChars-chartsContainer {
		margin-top: 30px;
		height: 210mm;
		font-family: $qualitas-font-text;
		font-weight: 500;
		font-size: 14px;
		text-align: center;
		padding: 20px 0px 0px 40px;
		display: grid;
		grid-template-columns: 330px 390px;
		grid-template-rows: repeat(2, 340px);
		grid-column-gap: 0px;
		grid-row-gap: 50px;
	}
	#fundsChars-chart2, #fundsChars-chart3, #fundsChars-chart1 {
		justify-self: center;
		width: 340px;
	}
	#fundsChars-chart1 {
		grid-area: 1 / 1 / 2 / 3;
	}
}