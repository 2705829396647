@import "../../../../App.scss";

#legalTerms {
  margin: 0;
  position: relative;
  height: 297mm;

  p {
    margin: 0;
  }

  .legalTerms-content {
    box-sizing: border-box;
    width: 100%;
}
  .legalTerms{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	column-gap: 20px;
	padding: 30px;
  }

  .content {
    width: 210mm;
    margin: 0;
    box-sizing: border-box;
	padding: 0;
  }

  .legalTerms-subtitle {
    font-size: 20px;
    font-family: $qualitas-font-title;
    font-weight: 700;
	  margin: 24px 0px 15px 0px;
  }
  .legalTerms-content-text{
    text-align: justify;
	font-size: 12px;
  }
  .legalTerms-content-table{
	width: 100%;
  }
  .legalTerms-content-row{
	display: flex;
	justify-content: space-between;
	padding: 4px 2px;
	font-size: 12px;
	gap: 10px;
	color: $qualitas-dark-grey;
	p:last-of-type{
		color:$qualitas-black;
		text-align: end;
	}
  }
  .odd{
	background-color: $qualitas-light-grey;
  }
  .legalTerms-note{
	margin-left: 30px;
	font-size: 9px;
  }
}
