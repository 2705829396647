@import '../../../../App.scss';
#fundEvolution {
	margin: 0;
	width: 210mm;
	height: 297mm;
	box-sizing: border-box;

	p {
		margin: 0;
	}
	.fundEvolution {
		width: 210mm;
		font-family: $qualitas-font-title;
		box-sizing: border-box;
	}
	.fundEvolution-header {
		background-color: #000000;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 210mm;
		height: 55px;
	}

	.fundEvolution-logoQualitas {
		width: 78px;		
		margin: 13px 0 11px 28px;
	}

	.fundEvolution-header-text {
		margin: 11px 36px 11px 0;
		
		p {
			margin: 0;
			font-size: 11px;
		}
	}


	.fundEvolution-content {
		padding: 30px;
	}
	.fundEvolution-line{
		img{
			margin: 40px 0 20px -30px;
			width: 70%
		}
	}
	.fundEvolution-title {
		font-family: $qualitas-font-title;
		font-weight: 700;
		font-size: 26px;
		margin-bottom: 20px;
	}
	.fundEvolution-subtitle {
		font-size: 18px;
		font-weight: 700;
	}
	.fundEvolution-subtitle:last-of-type {
		//margin-top: 60px;
	}

}