@import "../../../../App.scss";

#coinvestAcquisition {
	margin: 0;
	height: 297mm;

	p {
		margin: 0;
	}
	.coinvestAcquisition {
		margin-top: 40px;
		width: 210mm;
		font-family: $qualitas-font-text;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
	.coinvestAcquisition-item{
		min-height: 270px;
		margin-bottom: 30px;
	}

	.coinvestAcquisition-description-title{
		font-weight: 600;
		font-size: 13px;
		margin-bottom: 10px;
	}
	.coinvestAcquisition-description{
		padding: 0px 30px;
		margin-top: 20px;
		p{
			font-family: $qualitas-font-text;
			font-size: 12px;
		}
	}

	.coinvestAcquisition-headerInfo {
		/* height: 50px; */
		padding: 10px 0px;
		border-bottom: 1px solid $qualitas-grey;
		margin: 0px 30px; 
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: 1fr;
	  }
	  .coinvestAcquisition-headerInfo-item {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		font-size: 11px;
		padding: 5px 15px;
		border-right: 1px solid $qualitas-grey;
		img {
		  align-self: center;
		  max-height: 35px !important;
		  filter: grayscale(100%);
		  -webkit-filter: grayscale(100%);
		}
		p:last-of-type {
		  font-weight: 700;
		  font-size: 12px;
		}
	  }
	  .coinvestAcquisition-headerInfo-item:last-of-type {
		border: none;
	  }
	

	.coinvestAcquisition-logo-header img {
		max-width: 200px;
		height: 50px;
	  }
	  .coinvestAcquisition-logo-header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		height: 70px;
	  }
	  .coinvestAcquisition-logo-title {
		display: flex;
		align-items: center;
		padding: 0px 30px;
		height: 12mm;
		font-weight: 700;
		background-color: $qualitas-light-grey;
		justify-content: space-between;
		p {
		  font-family: $qualitas-font-title;
		  font-size: 26px;
		}
	  }
	  .coinvestAcquisition-logo-title-description {
		p {
		  font-family: $qualitas-font-text;
		  font-size: 14px;
		  font-weight: 300;
		  span {
			font-weight: 600;
			border-left: 1px solid $qualitas-grey;
			margin-left: 10px;
			padding-left: 10px;
		  }
		}
	  }
	
	

}