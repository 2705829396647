@import '../../../../App.scss';

#cover {
	margin: 0;

	.cover {
		height: 297mm;
		width: 210mm;
		padding: 95px;
		background-image: url("https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/new_cover.png");
		background-size: cover;
		color: white;
		box-sizing: border-box;
	}

	.cover-logoQualitas {
		width: 148px;
	}

	.cover-subtitle {
		margin-top: 200px;
		margin-bottom: 10px;
		font-family: $qualitas-font-text;
		font-size: 26px;
		font-weight: 300;
		width: 500px;
	}

	.cover-title {
		margin: 0px;
		font-family: $qualitas-font-title;
		font-weight: 500;
		font-size: 40px;
		line-height: 50px;
	}

	.cover-date {
		margin: 500px 0 0 0;
		font-family: $qualitas-font-title;
		font-size: 22px;

		span {
			color: $qualitas-green;
		}
	}

	.cover-confidential {
		font-size: 14px;
		font-weight: 300;
		margin-top: 10px
	}
}