@import './../../Variables.scss';

.status-tires-data{
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	gap: 20px;
	border: 1px dashed $qualitas-grey;
	padding: 20px;
	border-radius: 5px;
	width: 180px;
	margin: auto;
	height: 85px;
	.loading-icon, .ok-icon{
		font-size: 40px;
	}
	.ok-icon{
		color: $qualitas-green;
	}

}
