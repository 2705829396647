@import "../../../../App.scss";

#coinvestDetail {
  margin: 0;
  position: relative;
  height: 297mm;
  p {
    margin: 0;
  }

  .coinvestDetail {
    width: 210mm;
    font-family: $qualitas-font-text;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  .coinvestDetail-header-text {
    margin: 11px 36px 11px 0;

    p {
      margin: 0;
      font-size: 11px;
    }
  }
  .coinvestDetail-logo-header p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: grey;
    line-height: 10px;
    font-family: $qualitas-font-title;
  }
  .coinvestDetail-content {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
	max-height: 297mm;
  }
  .coinvestDetail-logo-header img {
    max-width: 200px;
    height: 50px;
  }
  .coinvestDetail-logo-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 70px;
  }
  .coinvestDetail-logo-title {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: 15px 30px;
    border-top: 10px solid $qualitas-green;
    justify-content: space-between;
    box-sizing: border-box;
    p {
      font-family: $qualitas-font-title;
      font-size: 30px;
    }
  }
  .coinvestDetail-logo-title-description {
    p {
      font-family: $qualitas-font-text;
      font-size: 14px;
      font-weight: 300;
      span {
        font-weight: 600;
        border-left: 1px solid $qualitas-dark-grey;
        margin-left: 10px;
        padding-left: 10px;
      }
    }
  }
  .coinvestDetail-stats {
    display: flex;
    width: 100%;
    padding: 10px 0px;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .coinvestDetail-stats table {
    width: 300px;
  }
  .coinvestDetail-stats table td {
    font-size: 12px;
  }

  .coinvestDetail-stats tr td:first-of-type {
    font-weight: 700;
    text-align: right;
    padding-right: 10px;
    width: 150px;
  }
  .coinvestDetail-stats tr td:last-of-type {
    padding-left: 10px;
    font-weight: 400;
    text-align: left;
  }
  .coinvestDetail-stats p,
  .coinvestDetail-table p,
  .coinvestDetail-evolution p,
  .coinvestDetail-compania {
    font-family: $qualitas-font-text;
    font-weight: 600;
    font-size: 14px;
  }
  .company-html {
    line-height: 16px;
    font-size: 12px;
    text-align: justify;
    height: 350px;
    overflow: hidden;
    p {
      margin-top: 15px;
    }
  }
  .coinvestDetail-stats-totals {
    border-bottom: 1px solid $qualitas-light-grey;
    background-color: rgb(248, 247, 247);
  }
  .coinvestDetail-description p {
	margin: 5px 0;
    font-size: 14px;
    font-weight: 700;
  }
  .coinvestDetail-description li {
    line-height: 15px;
    font-size: 10px;
    list-style-position: outside;
  }

  .coinvestDetail-description-table {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    padding: 0px 30px;
  }

  .coinvestDetail-description-html {
    font-size: 12px;
    list-style-position: outside;
    margin: 0;
    text-align: justify;
    padding-right: 20px;

  }

  .coinvestDetail-description ul {
    list-style: none;
    margin: 0;
  }
  .coinvestDetail-description li::before {
    content: "\2022";
    color: rgb(177, 177, 177);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  .coinvestDetail-ods {
    max-height: 42px;
    display: flex;
    gap: 4px;
    margin-top: 8px;
  }
  .coinvestDetail-ods img {
    max-height: 30px;
    width: auto;
    height: auto;
  }

  .coinvestDetail-table{
	margin: 30px 30px 0px 30px;
  padding: 0 0px 10px 0px;
   border-bottom: 1px solid $qualitas-black;
  }
  .coinvestDetail-table table {
	border-collapse:collapse;
	margin-top: 15px;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  .coinvestDetail-table td {
    line-height: 16px;
    font-size: 12px;
  }
  .coinvestDetail-table-header th{
    background-color: $qualitas-light-grey;
	padding: 8px 0px;
	font-weight: bold;
	font-family: $qualitas-font-text;
  }
  .coinvestDetail-table-header th:first-of-type{
	text-align: start;
	padding-left: 15px
  }
  .coinvestDetail-table-subheader th{
	padding: 5px 0px;
	font-size: 10px;
	border-bottom: 1px solid $qualitas-grey;
  }
  .coinvestDetail-table-header:last-of-type {
    color: grey;
    font-size: 12px;
    background-color: rgb(236, 236, 236);
  }
  .coinvestDetail-table tbody tr td:first-of-type {
    text-align: left;
  }
  .coinvestDetail-last-section {
    background-color: $qualitas-light-grey;
    padding: 20px 30px 10px 30px;
    margin-top: 25px;
  }
  .coinvestDetail-table-bold td {
	padding: 4px 0px;
    font-size: 13px;
	font-weight: 700;
  }

  .coinvestDetail-table-italic td {
    font-size: 11px;
	font-weight: 400;
  }
  .coinvestDetail-table-italic td:first-of-type,
  .coinvestDetail-table-bold td:first-of-type{
	padding-left: 15px;
  }
  .coinvestDetail-evolution{
    margin-top: 5px;
  }
  .coinvestDetail-evolution-legends {
    display: flex;
    margin: 20px 0px;
    justify-content: center;
    gap: 25px;
    p {
      font-size: 11px;
      font-weight: normal;
      span {
        display: inline-block;
        margin-right: 5px;
        height: 8px;
        width: 8px;
        border-radius: 8px;
        background-color: $qualitas-grey;
      }
    }
    p:last-of-type span {
      background-color: $qualitas-green;
    }
  }

  .coinvestDetail-headerInfo {
    /* height: 50px; */
    padding: 10px 30px;
    border-top: 1px solid $qualitas-grey;
    border-bottom: 1px solid $qualitas-grey;
    display: grid;
    grid-template-columns: 80px 100px 50px 90px 70px 100px 65px 70px 110px;
    grid-template-rows: 1fr;
  }
  .coinvestDetail-headerInfo-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    padding: 5px 9px;
    border-right: 1px solid $qualitas-grey;
    img {
      align-self: center;
      max-height: 35px !important;
      max-width: 80px !important;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
    p{
      text-align: center;
    }
    p:last-of-type {
      font-weight: 700;
      font-size: 12px;
    }
  }
  .coinvestDetail-headerInfo-item:last-of-type, .item-grey {
    border: none;
  }
  .item-grey {
	background-color: $qualitas-light-grey;
  }

  .chartLine {
    height: 250px;
    position: relative;
    z-index: 10;
    bottom: 250px;
    left: 248px;
    border-left: 2px dotted rgb(173, 173, 173);
  }
}
