@import '../../../../App.scss';
#portfolioInvestments {
	margin: 0;
	width: 210mm;
	height: 297mm;
	overflow: hidden;
	box-sizing: border-box;
	position: relative;

	p {
		margin: 0;
	}

	.content {
		width: 210mm;
		margin: 0;
		padding: 0;
		font-family: $qualitas-font-text;
	}

	.portfolioInvestments {
		padding: 35px 20px;
		font-family: $qualitas-font-text;
		box-sizing: border-box;
		position: relative;
	}
	.portfolioInvestments-content {
		box-sizing: border-box;
		width: 100%;
	}

	.portfolioInvestments-subtitle {
		font-size: 20px;
		font-family: $qualitas-font-title;
		font-weight: 700;
		span{
			font-family: $qualitas-font-title !important;
			color: $qualitas-dark-grey 
		}
	}

	.portfolioInvestments-header{
		display: flex;
		gap: 20px
	}

	.portfolioInvestments-headerInfo {
		width: 170px;
		padding: 10px 0px;
		margin-top: 12px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 60px 60px 60px;
	  }
	  .portfolioInvestments-headerInfo-item {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		font-size: 11px;
		padding: 5px 9px;
		border-top: 1px solid $qualitas-grey;
		img {
		  align-self: center;
		  max-height: 35px !important;
		  filter: grayscale(100%);
		  -webkit-filter: grayscale(100%);
		}
		p:last-of-type {
		  font-weight: 700;
		  font-size: 16px;
		}
	  }
	  .portfolioInvestments-headerInfo-item:last-of-type {
		border-bottom: 1px solid $qualitas-grey;
		border-top: 1px solid $qualitas-grey;
	  }

	  .portfolioInvestments-chart{
		margin-top: 20px;
		width: 250px;
		height: 150px;
	  }
	  .portfolioInvestments-table, .portfolioCoinvestments-table{
		width: 100%;
		font-family: $qualitas-font-text;
		font-size: 8px;
		border-collapse: collapse;
		font-weight: 600;
		margin-top: 14px;
		}
		tr > th{
			color: $qualitas-dark-grey;
			border-bottom: 1px solid $qualitas-black; 
			height: 25px;
		}
		td{
			text-align: center;
			font-size: 9px;
			padding: 3px 0 3px 5px;
			height: 25px;
			border-bottom: 1px solid $qualitas-grey;
			box-sizing: border-box;
		}
		td:first-of-type, th:first-of-type{
			text-align: start;
			font-weight: 700;
		}
		.progress-bar{
			width: 120px;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 10px;
		}
		tr:last-of-type td, .portfolioCoinvestments-table tr:nth-last-child(2) td {
			font-size: 10px;
			background-color: $qualitas-green;
			border-bottom: 1px solid $qualitas-black;
		}
		.portfolioCoinvestments-table tr:last-of-type td {
			background-color: $qualitas-black;
			color: $qualitas-white;
		}

}