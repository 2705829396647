@import "../../../../App.scss";
#newInvestments {
  margin: 0;
  width: 210mm;
  height: 297mm;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  p {
    margin: 0;
  }

  .content {
    width: 210mm;
    margin: 0;
    padding: 0;
    font-family: $qualitas-font-text;
  }

  .newInvestments {
    padding: 0px;
    font-family: $qualitas-font-text;
    box-sizing: border-box;
    position: relative;
  }
  .newInvestments-content {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 25px;
    margin-top: 20px;
    
  }
  .newInvestments-content p,  .newInvestments-chart > p:first-of-type{
    font-family: $qualitas-font-title;
    text-align: start;
		font-size: 20px;
		font-weight: 600;
  }

	.newInvestments-header {
		width: 210mm;
		box-sizing: border-box;
		padding: 22px 30px 20px 25px;
		background-color: $qualitas-light-grey;
		p{
			margin: 0;
		}
	}
	.newInvestments-header p:last-of-type{
		font-weight: 500;
		margin-top: 4px;
	}
  
  .newInvestments-subtitle {
		font-weight: 700;
		font-family: $qualitas-font-title;
		font-size: 26px;
  }

  .newInvestments-header {
    width: 100%;
  }


  .newInvestments-table{    
    width: 100%;
    font-family: $qualitas-font-text;
    font-size: 8px;
    border-collapse: collapse;
    font-weight: 600;
    margin-top: 10px;
  }
  tr > th {
    color: $qualitas-dark-grey;
    border-bottom: 1px solid $qualitas-black;
    height: 16px;
  }

  td {
    text-align: center;
    font-size: 9px;
    height: 31px;
    padding: 4px;
    border-bottom: 1px solid $qualitas-grey;
    box-sizing: border-box;
  }
  td:first-of-type,
  th:first-of-type {
    font-weight: 700;
  }
  .progress-bar {
    width: 120px;
	height: 22px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    p{
      font-size: 9px;
      font-family: $qualitas-font-text;
    }
  }

  .newInvestments-chart {
    margin-top: 15px;
    width: 210mm;
    padding-left: 25px;
    .newInvestments-chart-title {
      text-align: center;
    }
  }
  .newInvestments-chart-section {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newInvestments-chart-legend {
    display: grid;
    margin-top: 10px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1fr);
    height: 120px;
    text-align: center;
    p,
    span {
      font-size: 10px;
    }
    span {
      color: $qualitas-black;
    }
    .newInvestments-chart-legend-item {
      display: flex;
      width: 130px;
      flex-direction: column;
      align-items: center;
    }
    .newInvestments-chart-legend-item > div {
      height: 8px;
      width: 8px;
      border-radius: 10px;
    }
    .newInvestments-chart-legend-item p:nth-of-type(2){
      font-size: 12px;
      font-weight: 600;
    }
    .newInvestments-chart-legend-item:nth-child(odd) div {
      background-color: $qualitas-green;
    }
    .newInvestments-chart-legend-item:nth-child(even) div {
      background-color: #8f8f8f;
    }
  }
  .newInvestments-chart-labels {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    p {
      font-size: 12px;
    }
  }
  .newInvestments-table-header th {
    border: none;
  }
  .empty-box{
    margin-top: 20px;
    height: 60px;
    font-size: 13px;
    width: 100%;
    background-color: $qualitas-light-grey;
    color: $qualitas-dark-grey;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 20px;
  }
  .newInvestments-references{
    margin-top: 15px;
    display: flex;
    font-size: 10px;
    align-items: center;
    gap: 5px;
  }
	.reference {
		width: 8px;
		height: 8px;
		border-radius: 8px;
	}
	.ref-1{
		background-color: $qualitas-grey;
	}
	.ref-2{
		border: 1px solid $qualitas-dark-grey
	}
}
