@import "../../../../App.scss";
#glossary {
	margin: 0;
	height: 297mm;
	box-sizing: border-box;
	overflow: hidden;

	p {
		margin: 0;
	}
	.glossary {
		height: 297mm;
		width: 210mm;
		font-family: $qualitas-font-text;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}

	.glossary-last {
		height: 296mm;
		width: 210mm;
		font-family:  $qualitas-font-text;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
	.glossary-header {
		background-color: #000000;
		color: white;
		padding: 11px 36px 11px 28px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.glossary-logoQualitas {
		width: 78px;	}

	.glossary-header-text p {
		margin: 0;
		font-size: 11px;
	}
	.glossary-content {
		box-sizing: border-box;
		width: 100%;
		padding: 30px;
	}
	.glossary-content-title {
		margin-top: 15px;
		font-family: $qualitas-font-title;
		font-size: 24px;
		font-weight: bold;
	}
	.glossary-term-list {
		padding: 15px;
		display: grid;
		grid-template-columns: 160px 1fr;
		grid-auto-rows: minmax(70px, auto);
		grid-row-gap: 0px;
		font-size: 12px;
		line-height: 14px;
	}
	.glossary-term-item {
		padding: 15px;
		text-align: justify;
		border-bottom: 1px solid rgb(207, 207, 207);
	}
	.glossary-term-special-item,
    .glossary-term-item-special {
      padding: 0px 15px 15px 0px;
	  text-align: justify;
      border-bottom: 1px solid rgb(207, 207, 207);
    }
	.glossary-term-item-bold {
		font-family: $qualitas-font-title;
		font-size: 14px;
		padding: 15px;
		font-weight: bold;
		border-bottom: 1px solid rgb(207, 207, 207);
	}

	.glossary-term-special-item ul {
		margin-left: -15px;
		padding-right: 15px;
		list-style: none;
		li{
			font-size: 12px !important;
		}
	}
	.glossary-term-special-item td{
		font-size: 12px !important;
	}

	.glossary-term-item-lined {
      border-bottom: 1px solid$qualitas-black;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
	.glossary-term-item-form {
      font-family: $qualitas-font-title;
      display: flex;
      gap: 15px;
      align-items: center;
      padding-bottom: 5px;
	  padding-top: 10px;
      justify-content: center;
      font-size: 13px;
    }
	.centered{
		text-align: center;
	}
	.glossary-term-item-special-text {
      padding: 15px 0 0 15px;
    }

	.glossary-term-special-item li::before {
	content: "\2022";
		color: rgb(177, 177, 177);
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}

}