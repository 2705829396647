@import '../../../../App.scss';

#coinvestCover {
	margin: 0;
	height: 297mm;

	.coinvestCover {
		width: 210mm;
		height: 297mm;
		padding: 95px;
		background-image: url("https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/coinvest-back.jpg");
		background-size: cover;
		font-family: $qualitas-font-text;
		font-size: 26px;
		font-weight: 300;
		box-sizing: border-box;
	}
	p{
		width: 450px;
	}

	.coinvestCover-subtitle {
		margin: 20px 0;
	}
	.coinvestCover-title {
		margin: 200px 0 40px 0;
		font-family: $qualitas-font-title;
		font-weight: 700;
		font-size: 40px;
		line-height: 50px;
	}
}