@import '../../../../App.scss';
#portfolioCharts {
	margin: 0;
	width: 210mm;
	height: 297mm;

	p {
		margin: 0;
	}
	
	.portfolioCharts {
		width: 210mm;
		box-sizing: border-box;
	}

	.portfolioCharts-table-subtitle {
		border-top: 1px solid rgb(211, 211, 211);
		font-weight: bold;
	}

	.portfolioCharts-header {
		background-color: #000000;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 210mm;
		height: 55px;
	}

	.portfolioCharts-logoQualitas {
		width: 78px;		
		margin: 13px 0 11px 28px;
	}

	.portfolioCharts-header-text {
		margin: 11px 36px 11px 0;
		
		p {
			margin: 0;
			font-size: 11px;
		}
	}

	.portfolioCharts-content {
		box-sizing: border-box;
		width: 100%;
	}

	.portfolioCharts-subtitle {
		font-weight: 700;
		font-family: $qualitas-font-title;
		font-size: 26px;
		text-align: center;
	}

	.portfolioCharts-container {
		width: 210mm;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 20px 30px 20px 25px;
		background-color: $qualitas-light-grey;
		p{
			margin: 0;
		}
	}
	.portfolioCharts-container p:last-of-type{
		font-weight: 500;
		margin-top: 4px;
	}
	.portfolioCharts-boxes{
		display: flex;
		border-top: 1px solid $qualitas-grey;
		border-bottom: 1px solid $qualitas-grey;
		font-family: $qualitas-font-text;
	}
	.portfolioCharts-infoBox{
		padding: 0px 15px;
		margin: 10px 0px;
		font-size: 12px;
		border-right: 1px solid $qualitas-grey;

		span{
			font-weight: 600;
			font-size: 14px;;
			font-family: $qualitas-font-text;
		}
	}
	.portfolioCharts-infoBox:last-of-type{
		border: none;
	}

	.portfolioCharts-chartsContainer {
		height: 130mm;
		font-family: $qualitas-font-text;
		font-weight: 500;
		font-size: 14px;
		text-align: center;
		margin-top: 10px;
		display: grid;
		grid-template-columns: 340px 380px;
		grid-template-rows: repeat(1, 300px);
		gap: 20px;
	}
	#portfolioCharts-chart2, #portfolioCharts-chart3, #portfolioCharts-chart1 {
		justify-self: center;
		box-sizing: border-box;
	}
	.portfolioCharts-exposure{
		padding: 40px 25px 30px 25px;
		box-sizing: border-box;
	}
	.portfolioCharts-charts{
		padding-left: 25px;
		margin-top: 10px;
	}
	.portfolioCharts-exposure p, .portfolioCharts-charts p{
		font-family: $qualitas-font-title;
		font-size: 20px;
		font-weight: 600;
	}

	.portfolioCharts-table{
		width: 100%;
		font-family: $qualitas-font-text;
		font-size: 8px;
		border-collapse: collapse;
		font-weight: 600;
		margin-top: 15px;
		}
		tr > th{
			color: $qualitas-dark-grey;
			border-bottom: 1px solid $qualitas-black; 
			height: 35px;
			width: 150px;
		}
		td{
			text-align: center;
			font-size: 9px;
			padding: 3px 0 3px 5px;
			height: 26px;
			border-bottom: 1px solid $qualitas-grey;
			box-sizing: border-box;
		}
		td:first-of-type, th:first-of-type{
			text-align: start;
			font-weight: 700;
		}
		td:last-child{
			background-color: $qualitas-light-grey;
		}
		tr:last-of-type td {
			font-size: 10px;
			background-color: $qualitas-green;
			border-bottom: 1px solid $qualitas-black;
		}
		tr:last-of-type td:last-of-type {
			font-size: 10px;
			background-color: $qualitas-black;
			border-bottom: 1px solid $qualitas-black;
			color: $qualitas-white;
		}

		#chart-bars{
			margin-top: 20px;
		}
}