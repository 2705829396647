@import './../../Variables.scss';

.updates-container{
	margin-top: 26px;
	.steps-content{
		background-color: $qualitas-light-grey;
		margin: 20px 0px;
		padding: 40px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
	}
	.netCash-btn{
		margin-top: 14px;
		align-self: center;
	}
	.success-img{
		width: 100px;
		height: 100px;
		align-self: center;
	}
	a{
		color: $qualitas-black;
		font-weight: bold;
	}
}