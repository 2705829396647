@import '../../../../App.scss';
#fundSummary {
	margin: 0;
	width: 210mm;
	height: 297mm;

	p {
		margin: 0;
	}
	.fundSummary {
		width: 210mm;
		box-sizing: border-box;
	}
	.fundSummary-header {
		background-color: #000000;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 210mm;
		height: 55px;
		height: 55px;
	}

	.fundSummary-logoQualitas {
		width: 78px;
		margin: 13px 0 11px 28px;
	}

	.fundSummary-header-text {
		margin: 11px 36px 11px 0;
		
		p {
			margin: 0;
			font-size: 11px;
		}
	}

	.fundSummary-content-row {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		width: 100%;
		box-sizing: border-box;
	}
	.fundSummary-subtitle {
		font-family: $qualitas-font-title;
		font-weight: 700;
		font-size: 20px;
		padding-bottom: 15px;
	}

	.fundSummary-box {
		height: 168px;
		width: 168px;
		padding: 25px 30px;
		box-sizing: border-box;
		text-align: center;
		font-family: $qualitas-font-title;
		font-weight: 400;
		line-height: 37px;
		border-right:  1px solid $qualitas-grey;
	}
	.fundSummary-box:last-of-type{
		border-right: none;
	}
	.box4 {
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.fundSummary-box-text {
		font-family: $qualitas-font-title;
		font-size: 26px;
		font-weight: bold;
		span{
			font-family: $qualitas-font-title !important; 
			font-size: 24px;
		}
	}

	.fundSummary-box-subtext {
		font-family: $qualitas-font-text;
		font-size: 12px;
		margin-top: 3px;
		line-height: normal;
		white-space: pre-line;
	}
	.fundSummary-divider {
		border-top: 1px solid $qualitas-grey;
		margin-top: 15px;
		padding-top: 10px;
	}
	.fundSummary-summary {
		display: flex;
		gap: 15px;
		padding-bottom: 14px;
		align-items: center;
		justify-content: center;
	}
	.fundSummary-summary-section {
		display: flex;
		flex-direction: column;
	}
	.summary-section2 {
		padding: 5px 0 0 0;
		display: flex;
		border-bottom: none;
	}
	.fundSummary-summary-header {
		font-size: 10px;
		height: 15px;
		margin: 0;
		border-top: 1px solid $qualitas-grey;
	}
	.subtext-space {
		margin: 10px 0 5px 0;
	}
	.fundSummary-row-space{
		background-color: #FFF;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
		padding: 26px 25px;
	}
	.fundSummary-row-space:nth-child(2){
		background-color: #f7f7f7;
	}
	.fundSummary-row-space:nth-child(3){
		background-color: $qualitas-light-grey;
	}
}