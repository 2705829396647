@import '../../../../App.scss';
#portfolioFunds {
	margin: 0;
	width: 210mm;
	height: 297mm;
	overflow: hidden;
	box-sizing: border-box;
	position: relative;

	p {
		margin: 0;
	}

	.content {
		width: 210mm;
		margin: 0;
		padding: 0;
		font-family: $qualitas-font-text;
	}

	.portfolioFunds {
		font-family: $qualitas-font-text;
		box-sizing: border-box;
		position: relative;
	}
	.portfolioFunds-content {
		box-sizing: border-box;
		width: 100%;
	}
	.portfolioFunds-header{
		border-top: 10px solid $qualitas-green;
		width: 100%;
		height: 18mm;
		padding: 7px 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		p{
			font-size: 26px;
			font-family: $qualitas-font-title;
			font-weight: 700;
		}
		img{
			max-height: 10mm;
			max-width: 30mm;
			-webkit-filter: grayscale(100%); 
			filter: grayscale(100%); 		
		}
	}

	.portfolioFunds-mainBox{
		height: 70mm;
		background-color: $qualitas-light-grey;
		display: flex;
		gap: 15px;
		padding:  0px 5mm;
	}
	.portfolioFunds-numbers{
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 35px;
		border-right: 1px solid $qualitas-grey;
	}
	.portfolioFunds-numbers-summary{
		display: grid;
		grid-template-columns: 90px 110px 90px;
		//grid-column-gap: 4px;
	}
	.portfolioFunds-numbers-col{
		text-align: center;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 4px;
		gap: 5px;
		border-right: 1px solid $qualitas-grey;
		p:first-of-type{
			font-family: $qualitas-font-title;
			font-size: 24px;
			font-weight: 600;
		}
		p:last-of-type{
			font-family: $qualitas-font-text;
			font-size: 12px;
		}
	}
	.portfolioFunds-summary{
		width: 100%;
		padding: 35px 20px 0 0 
	}
	.portfolioFunds-summary-content{
		font-size: 12px;
		font-family: $qualitas-font-text;

		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 18px;
	}
	.portfolioFunds-summary-line{
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
		padding-bottom: 8px;
		border-bottom: 1px solid $qualitas-grey;
	}
	.bold{
		font-weight: 700;
	}
	.portfolioFunds-summary > p:first-child{
			font-family: $qualitas-font-title;
			font-size: 20px;
			font-weight: 600;
			margin: 15px 0px;
	}

	.portfolioFunds-numbers-col:last-of-type{
		border: none;
	}
	.portfolioFunds-data-summary{
		margin-left: 15px;
		display: flex;
		font-family: $qualitas-font-text;
		font-size: 12px;
		flex-wrap: wrap;
		gap: 5px;
		span{
			font-weight: bold;
		}
	}

	.portfolioFunds-figures{
		margin-top: 25px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 0px;
	}

	.portfolioFunds-evolution{
		padding: 30px 25px;
		p, span{
			font-family: $qualitas-font-title !important;
			font-size: 20px;
			font-weight: 600;
		}
		span{
			color: $qualitas-dark-grey;
		}
	}
	.portfoliofigures-first, .portfoliofigures-second, .portfoliofigures-third{
		border-right: 1px solid $qualitas-grey;
		p{
			font-family: $qualitas-font-text !important;
			font-size: 14px;
			font-weight: normal;
		}
		p:first-of-type{
			text-align: center;
		}
		.portfoliofigures-chart{
			width: 160px;
			margin-left: 30px;
		}
	}
	.portfoliofigures-second{
		padding: 0px 15px;
	}
	.portfoliofigures-third{
		border-right: none;
		padding: 0px 0px 0px 20px;
		width: 250px;
	}
	.portfoliofigures-chart-legend{
		display: grid;
		margin-top: 16px;
		margin-bottom: 5px;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 10px;
		text-align: center;
		p, span{
			font-size: 10px;
			font-family: $qualitas-font-text;
		}
		span{
			font-weight: 700;
			color:$qualitas-black;
		}
		.portfoliofigures-chart-legend-item{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.portfoliofigures-chart-legend-item > div{
			height: 8px;
			width: 8px;
			border-radius: 10px;
		}
		.portfoliofigures-chart-legend-item:nth-child(1) div{
			background-color: $qualitas-green;
		}
		.portfoliofigures-chart-legend-item:nth-child(2) div{
			background-color: #8f8f8f;
		}
		.portfoliofigures-chart-legend-item:nth-child(3) div{
			background-color: $qualitas-grey;
		}
	}
	.two-legends{
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 0px;
	}
	.four-legends{
		grid-template-columns: repeat(2, 1fr);
		padding: 0 50px;
		grid-column-gap: 0px;
		.portfoliofigures-chart-legend-item:nth-child(3) div{
			background-color: $qualitas-green;
		}
		.portfoliofigures-chart-legend-item:nth-child(4) div{
			background-color: #8f8f8f;
		}
	}
	.portfoliofigures-chart-labels{
		margin-top: 20px;
		display: flex;
		justify-content:space-around;
		p{
			font-size: 11px;
		}
	}
	.portfolioFunds-relevantFacts{
		background-color: $qualitas-light-grey;
		text-align: justify;
		padding: 20px 50px 30px 30px;
		height: 380px;
		p{
			font-size: 12px;
			line-height: 17px;
			strong{
				font-weight: normal;
			}
		}
		h4{
			margin: 0;
			font-family: $qualitas-font-title;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 14px;
		}
	}
	.portfolioFunds-analysis{
		padding: 30px 25px;
		p:first-of-type{
			font-family: $qualitas-font-title;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 10px;
		}

	}
	.analysis{
		border-bottom: 2px solid $qualitas-light-grey;
	}

	.portfolioFunds-analysis-table{
		width: 100%;
		font-family: $qualitas-font-text;
		font-size: 9px;
		border-collapse: collapse;
	}
	tr > th{
		color: $qualitas-dark-grey;
		border-bottom: 1px solid $qualitas-black; 
		height: 35px;
	}
	
	.manager-opinion {
		width: 8px;
		height: 8px;
		margin: auto;
		border-radius: 8px;
	}
	.portfolioFunds-analysis-references-moc{
		display: flex;
		gap: 8px;
	}
	.moc-reference {
		width: 8px;
		height: 8px;
		border-radius: 8px;
	}
	.ref-1{
		background-color: $qualitas-grey;
	}
	.ref-2{
		border: 1px solid $qualitas-dark-grey
	}
	td{
		text-align: center;
		font-size: 10px;
		height: 42px;
		border-bottom: 1px solid $qualitas-grey;
	}
	.portfolioFunds-analysis-small{
		font-size: 9px;
		text-align: start;
	}

	.portfolioFunds-analysis-grey{
		background-color: $qualitas-light-grey;
	}
	.opt-1{
		background-color: #15D63F;
	}
	.opt-2{
		background-color: #B3D615;
	}
	.opt-3{
		background-color: #FFE500;
	}
	.opt-4{
		background-color: #F03107;;
	}

	.portfolioFunds-analysis-references{
		font-size: 9px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-right: 22px;
		margin-top: 10px;
	
	}
	.portfolioFunds-analysis-ref-opinions{
		display: flex;
		gap: 15px;
	}
	.portfolioFunds-analysis-ref-opinion-item{
		display: flex;
		gap: 7px;
		align-items: center;
	}
	.progress-bar{
		width: 120px;
		height: 40%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 10px;
	}

}