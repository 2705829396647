@import "../../../../App.scss";
#underlyingFunds {
  margin: 0;
  width: 210mm;
  height: 297mm;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  p {
    margin: 0;
  }

  .content {
    width: 210mm;
    margin: 0;
    padding: 0;
    font-family: $qualitas-font-text;
  }

  .underlyingFunds {
    padding: 35px 20px;
    font-family: $qualitas-font-text;
    box-sizing: border-box;
    position: relative;
  }
  .underlyingFunds-content {
    box-sizing: border-box;
    width: 100%;
  }

  .underlyingFunds-subtitle {
    font-size: 20px;
    font-family: $qualitas-font-title;
    font-weight: 700;
    span {
      font-family: $qualitas-font-title !important;
      color: $qualitas-dark-grey;
    }
  }

  .underlyingFunds-header {
    display: flex;
	width: 100%;
	gap: 40px;
	align-items: center;
}

  .underlyingFunds-headerInfo {
    width: 170px;
    padding: 10px 0px;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 60px 60px;
  }
  .underlyingFunds-headerInfo-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    font-size: 11px;
    padding: 5px 9px;
    border-top: 1px solid $qualitas-grey;
    img {
      align-self: center;
      max-height: 35px !important;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
    p:last-of-type {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .underlyingFunds-headerInfo-item:last-of-type {
    border-bottom: 1px solid $qualitas-grey;
    border-top: 1px solid $qualitas-grey;
  }

  .underlyingFunds-table,
  .underlyingCoinvestments-table {
    width: 100%;
    font-family: $qualitas-font-text;
    font-size: 8px;
    border-collapse: collapse;
    font-weight: 600;
    margin-top: 10px;
  }
  tr > th {
    color: $qualitas-dark-grey;
    border-bottom: 1px solid $qualitas-black;
    height: 16px;
  }


  td {
    text-align: center;
    font-size: 9px;
    height: 25px;
    padding: 4px;
    border-bottom: 1px solid $qualitas-grey;
    box-sizing: border-box;
  }
  td:first-of-type,
  th:first-of-type {
    text-align: start;
    font-weight: 700;
  }
  .progress-bar {
    width: 120px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  tr:last-of-type td,
  .underlyingCoinvestments-table tr:nth-last-child(2) td {
    background-color: $qualitas-green;
    font-size: 10px;
    border-bottom: 1px solid $qualitas-black;
  }
  .underlyingCoinvestments-table tr:last-of-type td {
    background-color: $qualitas-black;
    color: $qualitas-white;
  }
  .underlyingFunds-chart {
	margin-left: 70px;
	width: 320px;
    p {
      font-family: $qualitas-font-text;
      font-size: 14px;
      font-weight: normal;
	  text-align: center;
    }
    .underlyingFunds-chart-title {
      text-align: center;
    }
  }
  .underlyingFunds-chart-section{
	display: flex;
	width: 350px;
	gap: 25px;
	align-items: center;
  }
  .underlyingFunds-chart-legend {
    display: flex;
	gap: 30px;
    margin-top: 10px;
	height: 80px;
    text-align: center;
    p,
    span {
      font-size: 10px;
    }
    span {
      font-weight: 700;
      color: $qualitas-black;
    }
    .underlyingFunds-chart-legend-item {
      display: flex;
      flex-direction: column;
      align-items: center;
	  gap: 5px;
    }
    .underlyingFunds-chart-legend-item > div {
      height: 8px;
      width: 8px;
      border-radius: 10px;
    }
    .underlyingFunds-chart-legend-item:nth-child(odd) div {
      background-color: $qualitas-green;
    }
    .underlyingFunds-chart-legend-item:nth-child(even) div {
      background-color: #8f8f8f;
    }
  }
  .underlyingFunds-chart-labels {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
	p{
		font-size: 12px;
	}
  }
  .underlyingFunds-table-combined-cells{
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 10px;
  }
  .underlyingFunds-table-header th{
    border: none;
  }
}
