@import '../node_modules/@qualitas/auth/dist/Auth.scss';
@import './Variables.scss';

html, body, #app {
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: none;
        scrollbar-color: $qualitas-black #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 16px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
        background-color: $qualitas-black;
        border-radius: 10px;
        border: 2px solid #fcfcfc;
    }
}

.header-row {
    height: 60px;
    padding: 0 16px;
    background-color: #fff;
    border-bottom: 1px solid lightgrey;

    .header-logo {
        padding: 10px;
        height: 100%;

        img {
            padding: 5px 0 0 20px;
            height: 80%;
        }
    }

    .header-icon {
        color: $qualitas-black;
        font-size: 18px;
    }
}

.aside-menu {
    width: 200px;

    .ant-menu {
        height: 100%;
        font-family: $qualitas-font-text !important;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.content {
    min-height: calc(100vh - 60px - 4vh);
    width: calc(100% - 200px);
    padding: 16px;

    .title {
        color: $qualitas-black;
        h3 {
            margin-right: 8px;
        }
    }

    .form-row {
        margin-bottom: 8px;
        padding-right: 16px;

        .form-element {
            display: flex;
            flex-direction: column;

            label {
                font-weight: bold;
                margin-bottom: 4px;
            }
        }
    }

    .generate-data-btn {
        margin: 16px 0;
    }

    .json-editor-section {
        padding-right: 16px;
    }

    .preview {
        width: 210mm;

        &-selector .ant-select {
            width: 100%;
        }

        .save-version-btn {
            margin-left: auto; 
            margin-right: 0;
			background-color: $qualitas-black;
			color: $qualitas-white;	
        }

        .template-container {
            border: 1px solid$qualitas-black;
            max-height: 297mm;
            overflow-x: hidden;
            overflow-y: scroll;
            
            .fundSummary {
                border: none;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}


.ant-menu-item-selected{
    color:$qualitas-black !important;
    background-color: $qualitas-light-grey !important;
}

.footer {
	background-color:$qualitas-black;
    color: #fff;
    padding: 10px;
    text-align: center;
	height: 40px;
}

.loading-skeleton{
    display: flex;
    flex-direction: column;
    gap: 10px
}

.report-generator{
    h3, span, label, button{
        font-family: $qualitas-font-text !important
    }
}

.btn-process{
	border: none;
	padding: 0px;
	background-color: transparent;
	cursor: pointer;
}

.process-btn{
	font-weight: bold;
	height: auto;
	width: 220px;
	span{
		font-weight: normal;
		font-size: 11px;
	}
}

@media print {
    * {
        -webkit-print-color-adjust: exact !important;
        /*Chrome, Safari */
        color-adjust: exact !important;
        /*Firefox*/
    }
    .pagebreak { page-break-before: always; }
}